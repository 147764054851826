export  function formatTime (date, format) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  const tempDate = [year, month, day].map(formatNumber),
    tempTime = [hour, minute].map(formatNumber)
  switch (format) {
    case 'YYYY-MM-DD':
    default:
      return tempDate.join('-')
      break
    case 'YYYY/MM/DD':
      return tempDate.join('/')
      break
    case 'YYYY.MM.DD':
      return tempDate.join('.')
      break
    case 'YYYY-MM-DD HH:mm':
      return tempDate.join('-') + ' ' + tempTime.join(':')
      break
    case 'YYYY-MM-DD HH:mm:ss':
      return tempDate.join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
      break
    case 'MM月DD日':
      return month + '月' + day + '日'
      break
    case 'MM-DD':
      return month + '-' + day
      break
    case 'HH:mm':
      return [hour, minute].map(formatNumber).join(':')
      break
    case 'MM月DD日 HH:mm':
      return month + '月' + day + '日 ' + [hour, minute].map(formatNumber).join(':')
      break
    case 'MM月DD日 HH:mm':
      return month + '月' + day + '日 ' + [hour, minute,].map(formatNumber).join(':')
      break
    case 'MM月DD日 HH:mm:ss':
      return month + '月' + day + '日' + [hour, minute, second].map(formatNumber).join(':')
      break
    case 'YY年MM月DD日 HH:mm':
      return year + '年' + month + '月' + day + '日' + [hour, minute].map(formatNumber).join(':')
      break
    case 'YY年MM月DD日 HH:mm:ss':
      return year + '年' + month + '月' + day + '日' + [hour, minute, second].map(formatNumber).join(':')
      break
  }
}
export function getkcard (url, name) {
  var reg = new RegExp('(^|&|/?)' + name + '=([^&|/?]*)(&|/?|$)', 'i')
  var r = url.substr(1).match(reg)
  if (r != null) {
    return r[2]
  }
  return null;

}
// 格式化时间,类似于这种2019-05-09T05:28:06.000+0000
export function formatTime1 (date, format) {
  const strDate = date
    .replace(/-/g, '/')
    .replace(/T/g, ' ')
    .replace(/(\.|\+)[\d]{3,4}/g, '')
  const tempDate = new Date(strDate)
  tempDate.setHours(tempDate.getHours() + 8)
  return formatTime(tempDate, format)
}
export function formatTime2 (date, format){
  const tempDate = new Date(date)
  // tempDate.setHours(tempDate.getHours() + 8)
  return formatTime(tempDate, format)
}
 const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

