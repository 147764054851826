import axios from 'axios'
var api = ""
// 判断是非为本地测试环境打开
if (document.domain == "192.168.1.41" || document.domain == "localhost" || document.domain =="ikaoyaner.cool-js.cool") {
	// 正式：https://h5.ikaoyaner.com/
	// 测试：https://dev.h5.ikaoyaner.com/
	// api = `https://dev.h5.ikaoyaner.com`;
	// api = `http://47.92.174.45:8513`;
	// api =  `http://dev.eng.yanxiaobai.com`
	// api="http://192.168.1.8:8513"
	api =  `http://dev.eng.yanxiaobai.com`
	
}

axios.defaults.timeout = 5000; //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'; //配置请求头
// axios.defaults.baseURL = '/backend';   //配置接口地址
// axios.defaults.baseURL =   process.env.NODE_ENV === 'production' ? '/api'  :  'https://kypro.xiaobaikaoyan.com';
//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
	//在发送请求之前做某件事
	// if(config.method  === 'post'){
	//     config.data = qs.stringify(config.data);
	// }
	return config;
}, (error) => {
	console.log('错误的传参')
	return Promise.reject(error);
});
//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) => {
	//对响应数据做些事
	if (!res.data.success) {
		return Promise.resolve(res);
	}
	return res;
}, (error) => {
	console.log('网络异常', error)
	return Promise.reject(error);
});

//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
	return new Promise((resolve, reject) => {
		axios.post(api + url, params, )
			.then(response => {
				resolve(response);
			}, err => {
				reject(err);
			})
			.catch((error) => {
				reject(error)
			})
	})
}
export function fetchPostToken(url, params) {
	var storage = window.sessionStorage;
	var token = storage.getItem('token')

	return new Promise((resolve, reject) => {
		axios.post(api + url, params, {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				resolve(response);
			}, err => {
				reject(err);
			})
			.catch((error) => {
				reject(error)
			})
	})
}
export function fetchPostTokenLive(url, params, token) {
	var storage = window.sessionStorage;

	return new Promise((resolve, reject) => {
		axios.post(api + url, params, {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				resolve(response);
			}, err => {
				reject(err);
			})
			.catch((error) => {
				reject(error)
			})
	})
}
////返回一个Promise(发送get请求)

export function fetchGet(url, param, ) {
	return new Promise((resolve, reject) => {
		// ac351b8a-2b78-4df3-ab75-5054bc4f3d5d

		axios.get(api + url, {
				params: param
			})
			.then(response => {
				resolve(response)
			}, err => {
				reject(err)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function fetchGetToken(url, param) {
	var storage = window.sessionStorage;
	// console.log(storage.getItem('token'))
	var token = storage.getItem('token')
	if (!/^\d+$/.test(token)) {
		return new Promise((resolve, reject) => {
			axios.get(api + url, {
					headers: {
						'Authorization': 'Bearer ' + token
					},
					params: param
				})
				.then(response => {
					resolve(response)
				}, err => {
					reject(err)
				})
				.catch((error) => {
					reject(error)
				})
		})
	} else {
		return new Promise((resolve, reject) => {
			axios.get(api + url, {
					params: param
				})
				.then(response => {
					resolve(response)
				}, err => {
					reject(err)
				})
				.catch((error) => {
					reject(error)
				})
		})
	}

}


export default {
	fetchGet,
	fetchGetToken,
	fetchPost,
	fetchPostToken,
	api,
	fetchPostTokenLive,
}
