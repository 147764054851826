<template>
	<div class="cont">
		<div class="top">
			<img class="top_img" :src="currentUser.avatarFile"></img>
			<div class="top_name">{{userInfo.nickname}}</div>
			<div class="top_text" v-if="!userInfo.vipInfo.fx.vipIds">你还没有开通VIP喔</div>
			<div class="top_text" v-else-if="currentUser.vipType == 2">你已开通 <span v-for="item in vipKList"
					v-if="userInfo.vipInfo.fx.vipIds[item.id]">「{{item.content}}」</span>，可升级VIP
			</div>
			<div class="top_text" v-else-if="currentUser.vipType == 3">{{currentUser.vipOverDate}}到期</div>
		</div>
		<div class="vi">
			<div class="vi_top">请选择会员类型</div>
			<div class="vip">
				<div :class="'vip_li '+(userInfo.vipInfo.fx.vipIds[item.id]?'vip_li_no':'')+(current == index?'vip_li_yes':'')"
					v-for="(item, index) in vipKList" @click="choice(index,userInfo.vipInfo.fx.vipIds[item.id])">
					<div class="vip_li_top">{{item.title}}</div>
					<div class="vip_li_h4">{{item.content}}</div>
					<div class="vip_li_price"><span>¥</span>{{item.groupPrice}}
						<div class="pin">拼团</div>
					</div>
					<div class="vip_li_text">¥{{item.current}}</div>
					<img class="vip_li_img" src="./img/yd_xsnd_d.png"></img>
					<img class="vip_li_img_bq_ygm" src="./img/bq_ygm.png"></img>
				</div>
			</div>
			<div class="vip_vi" v-if="!userInfo.vipInfo.fx.vipIds[vipKList[current].id]">
				<div class="vip_btn vip_btn01" @click="submit()">直接购买 ¥{{vipKList[current].current}}</div>

				<div class="vip_btn vip_btn02" @click="goPages(vipKList[current].yzUrl)">低价拼团
					¥{{vipKList[current].groupPrice}}
				</div>
			</div>
		</div>
		<div style="width: 100%;height: 22px;background: #F7F7F8;"></div>
		</button>
		<div class="imgs" v-html="vipKList[current].detail">
			<!-- <rich-text class="rich" nodes="{{vipKList[current].detail}}"></rich-text> -->
		</div>
		<van-loading class="loading" size="24px" v-if="Loadings" vertical>加载中...</van-loading>
	</div>
</template>

<script>
	var api = ""
	if (location.host.indexOf("dev") != -1) {
		api = "http://dev.kyapp.xiaobaikaoyan.com/api/";
	} else {
		api = "https://api.app.xiaobaitech.cn/api/";
	}


	import {
		Icon,
		Loading,
		Notify
	} from 'vant';

	import {
		formatTime1
	} from '../util/util'
	import https from '../https.js'
	import axios from 'axios';
	export default {
		components: {
			[Icon.name]: Icon,
			[Loading.name]: Loading,
		},
		data() {
			return {
				Loadings: false,
				current: 0,
				vipKList: [{
					id: ""
				}],
				userInfo: {
					vipInfo: {
						fx: {
							vipIds: [{
								id: ""
							}]
						}
					}
				},
				currentUser: {
					vipType: 1,
					vipOverDate: "",
					avatarFile: "",
				}
			}
		},
		created() {

			this.updateUser();
		},
		methods: {
			submit() {
				let storage = window.sessionStorage;
				let appid = storage.getItem('appid')
				let Openids = storage.getItem('Openids') ? JSON.parse(storage.getItem('Openids')) : {};
				https.fetchPostToken("/fx-api/wx/pay/createCourseOrder/mp?courseId=" + this.vipKList[this.current].cid +
						"&payType=1" +
						"&openid=" + Openids[appid], )
					.then(res => {
						console.log(res)
						if (res.data && res.data.data) {
							//调用微信支付接口
							if (typeof(WeixinJSBridge) == "undefined") {
								if (document.addEventListener) {
									document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
								} else if (document.attachEvent) {
									document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
									document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
								}
							} else {
								this.onBridgeReady(res.data.data);
							}
						} else {
							Toast(res.data.msg)
						}
					})
					.catch((error) => {
						console.log(error)
					})

			},
			onBridgeReady(data) {
				WeixinJSBridge.invoke(
					'getBrandWCPayRequest', {
						"appId": data.appId, //公众号名称，由商户传入     
						"timeStamp": data.timeStamp, //时间戳，自1970年以来的秒数     
						"nonceStr": data.nonceStr, //随机串     
						"package": data.packageValue, //微信生成的订单号 
						"signType": data.signType, //微信签名方式：     
						"paySign": data.paySign //微信签名 
					}, (res) => {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							//alert("充值成功！");
							//充值成功，写入数据库(总金额，充值记录)
							// ajaxSyncRequest("wx/chargeRecord", {
							// 	"amount": value
							// }, function(data) {
							// 	if (data.success) {
							// 		window.location.reload();
							// 	} else {
							// 		alert(data.msg);
							// 	}
							// });
							this.updateUser()
						}
					}
				);
			},
			goPages(url) {
				if (url) {
					window.location.href = url
				} else {
					Notify({
						type: 'warning',
						message: '暂无拼团！'
					});
				}
			},
			choice(index, ons) {
				if (!ons) {
					this.current = index;
				}
			},
			updateUser() {
				this.Loadings = true;
				https.fetchGetToken('/fx-api/user/currentUser', {}).then(res => {
					this.Loadings = false;
					console.log(res)
					if (res.data && res.data.data) {
						var currentUser = res.data.data;
						if (currentUser.vipOverDate) {
							currentUser.vipOverDate = formatTime1(currentUser.vipOverDate, "YYYY-MM-DD")
						}
						this.currentUser = currentUser;
						this.getUserInfo()
						console.log(this)
					}
				}).catch(err => {
					this.Loadings = false
					Notify({
						type: 'warning',
						message: '当前人数过多，请稍后重试'
					});
					console.log(err)
				})
			},
			getUserInfo() {
				axios.get(api + 'api/user/userInfo/' + this.currentUser.mobile, {})
					.then(res => {
						console.log(res)
						if (res.data && res.data.data) {
							let {
								vipInfo
							} = res.data.data;
							for (let i in vipInfo) {
								let vipIds = vipInfo[i].vipIds;
								let vipId = {}
								for (let s in vipIds) {
									vipId[vipIds[s]] = true
								}
								vipInfo[i].vipIds = vipId
							}
							res.data.data.vipInfo = vipInfo
							this.userInfo = res.data.data;
							this.vipList()
						}
					}, err => {
						console.log(err)
					})
					.catch((error) => {
						console.log(error)
					})
			},
			vipList() {
				axios.get(api + 'vip/list/group?market=fx', {})
					.then(res => {
						console.log(res)
						if (res.data && res.data.data) {
							let resD = res.data.data;
							for (let s in resD) {
								let list = resD[s].list;
								let type = resD[s].type;
								for (let i in list) {
									list[i].endTime = formatTime1(list[i].endTime, "YYYY.MM.DD")
									list[i].price = list[i].groupPrice;
									if (list[i].isDefault) {
										resD[s].currentSelect = list[i];
									}
									if (list[i] && list[i].detail) {
										list[i].detail = list[i].detail.replace(/<figure class=\"image\">/gi, "")
											.replace(/<\/figure>/gi, "").replace(/<img/gi, "<img class='imgS'")
									}
								}
								if (type == 6) {
									this.vipKList = list
								}
							}
							this.vips = resD;
							if (this.vipKList) {
								let vipKList = this.vipKList;
								let userInfo = this.userInfo;
								let vipInfo = userInfo.vipInfo;
								let fx = vipInfo.fx;
								let vipIds = fx.vipIds;
								console.log("vipKList", vipKList, vipIds, vipIds.length)
								let record = 0;
								for (let i in vipKList) {
									
									if(!vipIds[vipKList[i].id]){
										let current = vipKList[i].current;
										if (record < current) {
											record = record < current ? current : record;
											this.current = i
										}
									}
									
								}
							}
						}
					}, err => {
						console.log(err)
					})
					.catch((error) => {
						console.log(error)
					})
			},
			query1(name) {
				const search = location.search.substr(1); // 得到类似于 a=10&b=20&c=30
				const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i'); // i 忽略大小写
				const res = search.match(
					reg); // 返回格式 0: "a=10&" 1: "" 2: "10" 3: "&" groups: undefined index: 0 input: "a=10&b=20&c=30"
				if (res === null) {
					return null
				}
				return res[2];
			},
		}
	}
</script>

<style scoped="scoped">
	.tip {
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background-color: transparent;
	}

	.top {
		height: 280px;
		background-color: #282730;
		text-align: center;
	}

	.top_img {
		width: 88px;
		height: 88px;
		border: 4px solid #fff;
		border-radius: 50%;
		margin-top: 30px;
	}

	.top_name {
		font-size: 30px;
		font-weight: bold;
		color: #fff;
		margin-top: 14px;
	}

	.top_text {
		font-size: 24px;
		color: #B1ABAB;
		margin-top: 10px;
	}

	.vi {
		width: 750px;
		height: 640px;
		background: #FFFFFF;
		border-radius: 30px 30px 0px 0px;
		margin: -30px auto;
		overflow: hidden;
	}

	.vi_top {
		font-size: 32px;
		color: #4D4D54;
		margin: 48px 0;
		text-align: center;
	}

	.vip {
		padding: 0 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.vip_li {
		width: 218px;
		height: 270px;
		background: #FFFFFF;
		border-radius: 19px 19px 26px 19px;
		border: 4px solid #E5E4E4;
		position: relative;
		text-align: center;
		box-sizing: border-box;
	}

	.vip_li_top {
		width: 123px;
		height: 40px;
		background: linear-gradient(215deg, #F8D496 0%, #FFEBC0 100%);
		border-radius: 14px;
		font-size: 24px;
		color: #704B1D;
		line-height: 40px;
		text-align: center;
		position: absolute;
		left: 48px;
		top: -22px
	}

	.vip_li_h4 {
		font-size: 28px;
		color: #636363;
		font-weight: bold;
		margin-top: 60px;
		line-height: 40px;
	}

	.vip_li_price {
		font-size: 56px;
		font-weight: bold;
		color: #000000;
		margin-top: 26px;
		position: relative;
	}

	.vip_li_price text {
		font-size: 27px;
	}

	.vip_li_text {
		text-decoration: line-through;
		font-size: 28px;
		color: #AAA19B;
		margin-top: 6px;
	}

	.pin {
		width: 46px;
		height: 24px;
		line-height: 24px;
		background: #FFD4C7;
		border-radius: 9px 9px 9px 0;
		font-size: 12px;
		color: #EE4C01;
		position: absolute;
		right: 6px;
		top: -10px;
	}

	.vip_li_img {
		width: 40px;
		height: 40px;
		position: absolute;
		right: 0;
		bottom: 0;
		display: none;
	}

	.vip_li_yes {
		border: 4px solid #FF5F00;
	}

	.vip_li_yes .vip_li_top {
		background: linear-gradient(299deg, #FE8152 0%, #FF4F0C 100%);
		color: #fff;
	}

	.vip_li_yes .vip_li_h4 {
		color: #712E08;
	}

	.vip_li_yes .vip_li_price {
		color: #FF5F00;
	}

	.vip_li_yes .vip_li_img {
		display: block;
	}

	.vip_vi {
		margin-top: 70px;
		padding: 0 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.vip_btn {
		width: 334px;
		height: 98px;
		border-radius: 49px;
		text-align: center;
		line-height: 98px;
	}

	.vip_btn01 {
		background-color: #F9D798;
		font-size: 32px;
		color: #834737;
	}

	.vip_btn02 {
		background-color: #413B3B;
		font-size: 32px;
		color: #ffffff;
	}

	.rich .imgS {
		width: 100%;
	}

	.vip_li_no {
		filter: grayscale(100%)
	}

	.vip_li_img_bq_ygm {
		width: 84px;
		height: 74px;
		position: absolute;
		right: 0;
		bottom: 0;
		display: none;
	}

	.vip_li_no .vip_li_img_bq_ygm {
		display: block;
	}

	.vip_li_no .vip_li_img {
		display: none;
	}
</style>
