<template>
	<div class="cont">
		<div class="b">
			<div class="top">
				<img class="top_img" src="./img/hy_bj.png"></img>
				<div class="top_vi">
					<div class="top_zuo">
						<div class="zuo_h3">复试模板VIP</div>

						<div class="zuo_text" v-if="currentUser.vipOverDate">
							{{currentUser.vipOverDate}}到期
						</div>
						<div class="zuo_text" v-else>帮你复试通关上岸</div>
						<div class="zuo_img_vi">
							<img class="zuo_img" :src="currentUser.avatarFile"></img>
							{{currentUser.vipType==1?'还未开通VIP喔':'已开通'+currentUser.vipTitle}}
						</div>
					</div>
					<div class="top_you" v-if="currentUser.vipType == 1" @click="go()"
						data-url="./openingVIP/index">去开通
						<div class="you_vi">
							<van-icon name="arrow" color="#FCEBCC" />
						</div>
					</div>
					<div class="top_you" v-if="currentUser.vipType == 2" @click="go()"
						data-url="./openingVIP/index">去升级
						<div class="you_vi">
							<van-icon name="arrow" color="#FCEBCC" />
						</div>
					</div>
					<div class="top_you" v-if="currentUser.vipType == 3" @click="go()"
						data-url="./openingVIP/index">已开通
						<div class="you_vi">
							<van-icon name="arrow" color="#FCEBCC" />
						</div>
					</div>

				</div>
			</div>
		</div>
		<img class="bdqy" src="./img/bdqy.png"></img>
		<div class="body">
			<div class="body_vi">
				<img class="jian01" src="./img/icon_jt_sz3.png"></img>
				<div class="body_li">
					<img class="li_img" src="./img/icon_fsmbtg.png"></img>
					<div class="li_h4">复试模板通关</div>
					<div class="li_text">帮你1小时搞定复试模板</div>
				</div>
				<img class="jian02" src="./img/icon_jt_sz1.png"></img>
			</div>
			<div class="body_vi" style="margin-top: 16px;">
				<div class="body_li">
					<img class="li_img" src="./img/icon_zwjsdz.png"></img>
					<div class="li_h4">自我介绍定制</div>
					<div class="li_text">帮你定制个性化专属模板</div>
				</div>
				<div class="body_li" style="margin-left: 16px;">
					<img class="li_img" src="./img/icon_zywdmb.png"></img>
					<div class="li_h4">自由问答模版</div>
					<div class="li_text">帮你搞定32个高频问答</div>
				</div>
			</div>
			<img class="icon_jt_sz2" src="./img/icon_jt_sz2.png"> </img>
			<div class="body_text" style="margin-top: 30px;">复试会问的问题都在这</div>
			<div class="body_text">搞定口语，直接背答案</div>
		</div>
		<div class="di">
			<!-- <div class="di_bnt btn_01" catchtap="goPage" data-url="/pages/index/unlock/index">
				<div class="bnt_h3">兑换会员</div>
				<div class="bnt_text">购买后可解锁</div>
			</div> -->
			<div class="di_bnt btn_02" @click="go()">
				<div class="bnt_h3" style="color: #834737;">购买会员</div>
				<div class="bnt_text" style="color: #B07E5C;">限时低价拼团</div>
			</div>
		</div>
		<div style="height: 40px;"></div>
		<van-loading class="loading" size="24px" v-if="Loadings" vertical>加载中...</van-loading>
	</div>

</template>

<script>
	// var api = ""
	// if (location.host.indexOf("dev") != -1) {
	// 	api = "http://dev.eng.yanxiaobai.com/fx-api";
	// } else {
	// 	api = "https://eng.yanxiaobai.com/fx-api";
	// }
	import {
		Icon,
		Loading,
		Notify,
		Dialog 
	} from 'vant';

	import {
		formatTime1
	} from '../util/util'
	import https from '../https.js'
	export default {
		components: {
			[Icon.name]: Icon,
			[Loading.name]: Loading,
			[Notify.name]: Notify,
			[Dialog.name]: Dialog,
		},
		data() {
			return {
				Loadings: false,
				currentUser: {
					vipType: 1,
					vipOverDate: "",
					avatarFile: "",
				}
			}
		},
		created() {
			// https://eng.yanxiaobai.com/#/loginVip/?appid=wx2b13c14aae25044f
			// http://dev.eng.yanxiaobai.com/#/loginVip/?appid=wx2b13c14aae25044f
			// Openids  {"wx2b13c14aae25044f":"orV6K58UI8CN4CPtdqvHn6BnX-eA"}
			// appid  wx2b13c14aae25044f
			// tokens {"wx2b13c14aae25044f":"cb6a9a5b-08f1-46e9-803c-777af3821a5f"}
			// token cb6a9a5b-08f1-46e9-803c-777af3821a5f
			// 新增复试会员 购买
				
			var appid = this.$route.query.appid;
			var storage = window.sessionStorage;
			if (appid) {
				storage.setItem('appid', appid);
			}
			let redirect_url = encodeURIComponent(location.origin + "/#/loginVip");
			console.log("appid", appid, redirect_url)
			let url =
				`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_base`;
			let code = this.query1("code");
			if (!this.query1("code")) {
				window.location.href = url
			} else {
				let storage = window.sessionStorage;
				let appid = storage.getItem('appid')
				let Openids = storage.getItem('Openids') ? JSON.parse(storage.getItem('Openids')) : {};
				this.Openid = Openids[appid];
				console.log("appid2",appid)
				if (Openids[appid]) {
					this.doLogin(Openids[appid])
				} else if (code) {
					this.getOpenId(code)
				} else {}
				// this.agreementVIP()
				// this.countAllLikeTogether();
			}
		},
		methods: {
			go(e){
				this.$router.push({
					name: 'openingVIP',
					query: {
					}
				})
			},
			updateUser() {
				let storage = window.sessionStorage;
				this.Loadings = true;
				https.fetchGetToken('/fx-api/user/currentUser', {}).then(res => {
					this.Loadings = false
					console.log(res)
					if (res.data && res.data.data) {
						var currentUser = res.data.data;
						if (currentUser.vipOverDate) {
							currentUser.vipOverDate = formatTime1(currentUser.vipOverDate, "YYYY-MM-DD")
						}
						this.currentUser = currentUser;
					}
				}).catch(err => {
					this.Loadings = false
					Notify({
						type: 'warning',
						message: '当前人数过多，请稍后重试'
					});
					console.log(err)
				})
			},
			doLogin(Openid) {
				let storage = window.sessionStorage;
				let appid = storage.getItem('appid')
				https.fetchPost(
						'/fx-api/oauth/token?client_id=app&client_secret=app_secure&grant_type=password&password=&platform=weapp&loginWay=666789&username=' +
						Openid, {})
					.then(res => {
						console.log(res)
						if (res.data) {
							var access_token = res.data.access_token;
							let tokens = storage.getItem('tokens') ? JSON.parse(storage.getItem('tokens')) : {};
							tokens[appid] = access_token
							tokens = JSON.stringify(tokens)
							storage.setItem('tokens', tokens);
							storage.setItem('token', access_token);
							this.updateUser()
						}
						if(res.error_description == "UserDetailsService returned null, which is an interface contract violation"){
							Dialog.alert({
								title: '提示',
								message: '请先登录小程序再来登入哦！',
							}).then(() => {
								// on close
							});
						}
					})
					.catch((error) => {
						Notify({
							type: 'warning',
							message: '当前人数过多，请稍后重试'
						});
						console.log(error)
					})
			},
			getOpenId(code) {
				let storage = window.sessionStorage;
				let appid = storage.getItem('appid')
				https.fetchGet('/fx-api/mp/getOpenId/' + appid + '/' + code, {})
					.then(res => {
						if (res.data && res.data.data) {
							var Openid = res.data.data
							this.Openid = Openid;
							var storage = window.sessionStorage;
							let Openids = storage.getItem('Openids') || {}
							Openids[appid] = Openid
							Openids = JSON.stringify(Openids)
							storage.setItem('Openids', Openids);
							this.doLogin(Openid)
						}
					})
					.catch((error) => {
						Notify({
							type: 'warning',
							message: '当前人数过多，请稍后重试'
						});
						console.log(error)
					})
			},
			query1(name) {
				const search = location.search.substr(1); // 得到类似于 a=10&b=20&c=30
				const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i'); // i 忽略大小写
				const res = search.match(
					reg); // 返回格式 0: "a=10&" 1: "" 2: "10" 3: "&" groups: undefined index: 0 input: "a=10&b=20&c=30"
				if (res === null) {
					return null
				}
				return res[2];
			},
		}
	}
</script>

<style scoped="scoped">
	.loading {
		width: 300px;
		padding: 60px 0;
		border-radius: 12px;
		position: fixed;
		left: 50%;
		top: 50%;
		z-index: 10;
		background-color: #fff;
		border: 1px solid #E6E6E6;
		margin-left: -150px;

	}

	.cont {
		overflow: hidden;
		background: #F5F5F5;
		min-height: 100vh;
	}

	.b {
		width: 750px;
		height: 340px;
		background: linear-gradient(180deg, #AFB2B5 0%, rgba(229, 229, 230, 0) 100%);
		overflow: hidden;
	}

	.top {
		width: 670px;
		height: 232px;
		background: linear-gradient(181deg, #2A2A2A 0%, #2C2C2C 100%);
		border-radius: 20px;
		margin: 24px auto 0;
		position: relative;
	}

	.top_img {
		width: 100%;
		height: 100%;
	}

	.top_vi {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0 40px;
	}

	.zuo_h3 {
		font-size: 36px;
		color: #F4D2A2;
		font-weight: bold;
		line-height: 50px;
	}

	.zuo_text {
		font-size: 24px;
		color: #C4B49F;
		line-height: 26px;
		margin-top: 10px;
	}

	.zuo_img_vi {
		display: flex;
		align-items: center;
		margin-top: 40px;
		color: #CFC7BD;
		font-size: 24px;
	}

	.zuo_img {
		width: 54px;
		height: 54px;
		margin-right: 10px;
		border-radius: 50%;
	}

	.top_you {
		padding: 0 24px;
		height: 48px;
		background: linear-gradient(106deg, #FBE7B0 0%, #FCEBCE 100%);
		border-radius: 29px;
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 24px;
		color: #483214;
		font-weight: bold;
	}

	.you_vi {
		width: 22px;
		height: 22px;
		background: #483214;
		font-weight: bold;
		border-radius: 50%;
		font-size: 14px;
		margin-left: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bdqy {
		display: block;
		width: 670px;
		height: 40px;
		margin: -74px auto 0;
	}

	.body {
		width: 670px;
		padding: 48px 35px 48px;
		background: #FFFFFF;
		border-radius: 22px;
		box-sizing: border-box;
		margin: 32px auto;
	}

	.body_vi {
		display: flex;
		justify-content: center;
	}

	.jian01,
	.jian02 {
		width: 34px;
		height: 34px;
		margin-top: 162px;
	}

	.jian01 {
		margin-right: 40px;
	}

	.jian02 {
		margin-left: 40px;
	}

	.body_li {
		width: 312px;
		height: 200px;
		background: #F5F5F5;
		border-radius: 28px;
		text-align: center;
		box-sizing: border-box;
		padding-top: 26px;
	}

	.li_img {
		width: 70px;
		height: 70px;
	}

	.li_h4 {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 42px;
		margin-top: 10px;
	}

	.li_text {
		font-size: 24px;
		color: #B8BCBF;
		line-height: 33px;
		margin-top: 2px;
	}

	.icon_jt_sz2 {
		width: 34px;
		height: 34px;
		display: flex;
		margin: 12px auto 0;
	}

	.body_text {
		width: 550px;
		height: 48px;
		line-height: 48px;
		text-align: center;
		background: linear-gradient(270deg, #fff 0%, #FFECEE 48%, #fff 100%);
		font-size: 32px;
		font-weight: bold;
		margin: 20px auto 0;
	}

	.di {
		margin-top: 10px;
		padding: 0 30px;
		display: flex;
		justify-content: space-between;
	}

	.di_bnt {
		width: 334px;
		height: 98px;
		color: #fff;
		border-radius: 49px;
		text-align: center;
		margin: 0 auto;
	}

	.btn_01 {
		background-color: #413B3B;
	}

	.btn_02 {
		width: 634px;
		background-color: #F9D798;
	}

	.bnt_h3 {
		font-size: 32px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 45px;
		margin-top: 8px;
	}

	.bnt_text {
		font-size: 24px;
		color: #CCCCCC;
		line-height: 33px;
	}
</style>
